import data from '../../views/JS/OrderList.json'
import completedData from '../../views/JS/CompletedOrderList.json'
import DownloadButton from '../../views/components/DownloadButton.vue'
import config from "../../../config";

export default {
    data() {
        return {
            search:[],
            dataDown:[],
            userName: '',
            password: '',
            modelNotes: '',
            data: [],
            completedData: [],
            ongoingData: [],
            warehouseData: [],
            thisDayName: '',
            thisDay: '',
            thisMonthName: '',
            thisYear: '',
            adminValue: false,
            openModal: false,
            activeOrder: true,
            createUser: true,
            selectedCari: {},
            FiltersCariList: [],
            completedOrder: false,
            warehouseOrder: false,
            ongoingOrder: false,
            CommentedItem: {},
            id: this.$route.params.id,
            merchant_id: JSON.parse(localStorage.getItem('merchant_id')),
            permission_id: JSON.parse((JSON.parse(localStorage.getItem('jsonPayload')))),
            searchTextAdminActive:'',
            searchTextAdminComplate:'',
            searchTextAdminOnGoing:'',
            searchTextAdminWareHouse:'',

        }
    },

    mounted() {
        this.orderList();
        this.cariList();
        this.endOrderList();
        this.deliveryList();
        this.ongoingorderList();
    },
    watch: {
        searchTextAdminActive(){
            console.log(this.searchTextAdminActive);

            this.orderList();


        },
        searchTextAdminComplate(){
            console.log(this.searchTextAdminComplate);
            this.deliveryList();
        },
        searchTextAdminOnGoing(){
            console.log(this.searchTextAdminOnGoing);
            this.ongoingorderList();
        },
        searchTextAdminWareHouse(){
            console.log(this.searchTextAdminWareHouse);
            this.endOrderList();
        }
    },
    components: {DownloadButton},

    computed: {},
    destroyed() {


    },

    methods: {
        getFiltered(e){
            console.log("bursa",e) //output the filtered items
        },
        notes(item) {
            console.log("bunun", item)
            this.openModal = false
            this.modelNotes = ''
            this.openModal = true
            this.createUser = false
            this.CommentedItem = item
            this.selectedIndexID = item.IndexID
            this.$http.get(`${config.API_BASE}/api/v1/ordernote/${this.selectedIndexID}`, this.config).then((response) => {
                this.noteList = response.data

            });
        },
        addNote() {
            const formData = new FormData();
            formData.append("IndexID", this.selectedIndexID);
            formData.append("Username", localStorage.getItem('user_name'));
            formData.append("Note", this.modelNotes);

            this.$http.post(`${config.API_BASE}/api/v1/ordernote`, formData, this.config).then((response) => {

                this.$http.get(`${config.API_BASE}/api/v1/ordernote/${this.selectedIndexID}`, this.config).then((response) => {
                    this.noteList = response.data
                    this.modelNotes = ''
                });

            });
        },
        cariList() {
            this.$http.get(`${config.API_BASE}/api/v1/adduser`, this.config).then((response) => {
                console.log("bakCari", response)
                this.FiltersCariList = response.data

            });
        },
        orderList() {
            const defaultBody = {
                search: this.searchTextAdminActive
            };
            this.data=[]
            this.ongoingData=[]
            this.$http.get(`${config.API_BASE}/api/v1/order`,{
                params:defaultBody
            }, this.config).then((response) => {
                console.log("bakAdmin", response.data)
                this.data=response.data
                this.dataDown=response.data
                console.log("öhüü",this.dataDown)
            });
        },
        endOrderList() {
            const defaultBody = {
                search: this.searchTextAdminWareHouse
            };
            this.warehouseData = []

            this.$http.get(`${config.API_BASE}/api/v1/enddelivery`,{
                params:defaultBody
            }, this.config).then((response) => {
                console.log("bakOrder", response.data)
                this.warehouseData = response.data;
                console.log("buuuu", this.warehouseData)

            });
        },
        deliveryList() {
            const defaultBody = {
                search: this.searchTextAdminComplate
            };
            this.completedData = []
            this.$http.get(`${config.API_BASE}/api/v1/delivery`, {
                params:defaultBody
            },this.config).then((response) => {
                console.log("bakOrder", response.data)
                this.completedData = response.data;
                console.log("buuuu", this.completedData)

            });
        },
        ongoingorderList() {
            const defaultBody = {
                search: this.searchTextAdminOnGoing
            };
            this.ongoingData = []
            this.$http.get(`${config.API_BASE}/api/v1/ongoingorder`,{
                params:defaultBody
            }, this.config).then((response) => {
                console.log("bakOrder", response.data)
                this.ongoingData = response.data;
                console.log("buuuu", this.ongoingData)

            });
        },
        addUser() {
            const formData = new FormData();
            formData.append("Username", this.userName);
            formData.append("Password", this.password);
            formData.append("CariIND", this.selectedCari);
            formData.append("UserType", 2);
            this.$http.post(`${config.API_BASE}/api/v1/adduser`, formData, this.config).then((response) => {


                setTimeout(() => {
                    this.openModal = false
                    this.$vs.notify({
                        color: 'success',
                        title: 'Success',
                        text: 'User Created.'
                    })
                }, 1000);
            });
        },
        changeActive() {
            this.activeOrder = true
            this.completedOrder = false
            this.ongoingOrder = false
            this.warehouseOrder = false
            this.orderList();
        },
        changeCompleted() {
            this.activeOrder = false
            this.ongoingOrder = false
            this.completedOrder = true
            this.warehouseOrder = false
            this.deliveryList();
        },
        changeWarehouse() {
            this.activeOrder = false
            this.ongoingOrder = false
            this.completedOrder = false
            this.warehouseOrder = true
            this.endOrderList();
        },
        changeOngoing() {
            this.activeOrder = false
            this.completedOrder = false
            this.ongoingOrder = true
            this.warehouseOrder = false
            this.ongoingorderList();
        },
        sendButton(item, i) {

            console.log(item)
            this.$router.push({
                name: 'transfer',
                params: {
                    selected: JSON.stringify(item),
                    index: i,

                }
            })
        },

        openModalUser() {
            this.openModal = true
            this.createUser = true
        },
        closeModal() {
            this.openModal = false
            this.modelNotes = ''
            if (this.activeOrder === true){
                this.orderList();
            }
            else if (this.ongoingOrder === true){
                this.ongoingorderList();
            }
            else if (this.completedOrder === true){
                this.deliveryList();
            }
            else if (this.warehouseOrder === true){
                this.endOrderList();
            }

        }

    }
}
